<script lang="ts">
    export let authUser;
    import { SearchCheck } from 'lucide-svelte';
    import StatusCard from '@/Components/StatusCard.svelte';
    import CustomTabs from '@/Components/CustomTabs.svelte';
    import PostSummary from '@/Components/PostSummary.svelte';
    import AppealForm from '@/Components/AppealForm.svelte';
    import { getTheme } from '@/utility/theming/darkmode.ts';

    let posts = [
        {
            postId: '1237938',
            publishingTime: '12.12.2024',
            caption: 'Paris!',
            mediaPreview: ['/svg/11.jpeg', '/svg/22.jpeg'],
            status: 'manual review',
        },
    ];
    let tabs = ['Overview', 'Posts', 'Appeals'];
    let activeTab = 'Overview';

    const handleTabChange = (tab: string) => {
        activeTab = tab;
    };
</script>

<div class="flex h-[100vh] w-[100%] flex-col items-start p-[2rem] dark:text-white">
    <div
        class="flex w-[100%] flex-col items-center justify-center gap-2 md:flex-row md:items-center md:justify-start md:gap-4"
    >
        <SearchCheck class="h-[40px] w-[40px] " />
        <h5 class="text-truncate text-[35px] font-bold dark:text-white">Compliance Center</h5>
    </div>

    <CustomTabs {tabs} {activeTab} onTabChange="{handleTabChange}" />

    {#if activeTab === 'Overview'}
        <div class=" mt-3 flex w-[100%] flex-col items-center justify-start gap-4 sm:flex-row md:items-start md:gap-6">
            <StatusCard title="Account Standing" value="{100}" info="Account standing information" />
            <StatusCard title="Strikes last 12 months" value="{10}" info="Account standing information" />
        </div>
        <div class="mt-4">
            <p class="mt-4 pl-2">No issues with your account.</p>
        </div>
    {/if}
    {#if activeTab === 'Posts'}
        <PostSummary data="{posts}" />
    {/if}
    {#if activeTab === 'Appeals'}
        <AppealForm />
    {/if}
</div>
